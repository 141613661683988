// 20221201163019
// https://vnrebates.info/wp-json/wp/v2/categories?per_page=100&page=1

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    "id": 3908,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./altcoin",
    "name": "Altcoin",
    "slug": "altcoin",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3908"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3908"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3968,
    "count": 5,
    "description": "",
    "link": "https://vnrebates.info/./aud",
    "name": "AUD",
    "slug": "aud",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3968"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3968"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3750,
    "count": 38,
    "description": "",
    "link": "https://vnrebates.info/./aud-usd",
    "name": "AUD/USD",
    "slug": "aud-usd",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3750"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3750"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3945,
    "count": 5,
    "description": "",
    "link": "https://vnrebates.info/./back-test",
    "name": "Back test",
    "slug": "back-test",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3945"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3945"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3695,
    "count": 14,
    "description": "",
    "link": "https://vnrebates.info/./special-topic/banker-sharing",
    "name": "Banker_sharing",
    "slug": "banker-sharing",
    "taxonomy": "category",
    "parent": 3693,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3695"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "up": [
        {
          "embeddable": true,
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3693"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3695"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3975,
    "count": 1,
    "description": "Tổng hợp những mẩu chuyện, những tin tức bên lề trading hay những câu chuyện ít người biết đằng sau thành công của những huyền thoại trader.",
    "link": "https://vnrebates.info/./ben-le-trading",
    "name": "Bên lề Trading",
    "slug": "ben-le-trading",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "2"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3975"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3975"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3942,
    "count": 8,
    "description": "",
    "link": "https://vnrebates.info/./bieu-do-charting",
    "name": "Biểu đồ - Charting",
    "slug": "bieu-do-charting",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3942"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3942"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 9,
    "count": 37,
    "description": "",
    "link": "https://vnrebates.info/./bitcoin",
    "name": "Bitcoin - BTC",
    "slug": "bitcoin",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/9"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=9"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 2,
    "count": 143,
    "description": "",
    "link": "https://vnrebates.info/./blog",
    "name": "Blog",
    "slug": "blog",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/2"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=2"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3998,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./cac-dong-coin-tren-the-gioi",
    "name": "Các đồng coin trên thế giới",
    "slug": "cac-dong-coin-tren-the-gioi",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "1"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3998"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3998"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3926,
    "count": 9,
    "description": "",
    "link": "https://vnrebates.info/./cac-yeu-to-anh-huong-den-gia-vang",
    "name": "Các yếu tố ảnh hưởng đến giá vàng",
    "slug": "cac-yeu-to-anh-huong-den-gia-vang",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3926"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3926"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3916,
    "count": 1,
    "description": "",
    "link": "https://vnrebates.info/./cach-dao-bitcoin",
    "name": "Cách đào bitcoin",
    "slug": "cach-dao-bitcoin",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3916"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3916"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 4006,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./cach-lam-giau",
    "name": "Cách làm giàu",
    "slug": "cach-lam-giau",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/4006"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=4006"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3974,
    "count": 3,
    "description": "",
    "link": "https://vnrebates.info/./cad",
    "name": "CAD",
    "slug": "cad",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3974"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3974"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3965,
    "count": 26,
    "description": "",
    "link": "https://vnrebates.info/./cau-chuyen-trader",
    "name": "Câu chuyện trader",
    "slug": "cau-chuyen-trader",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "2"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3965"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3965"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3973,
    "count": 4,
    "description": "",
    "link": "https://vnrebates.info/./chf",
    "name": "CHF",
    "slug": "chf",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3973"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3973"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3961,
    "count": 2,
    "description": "",
    "link": "https://vnrebates.info/./chi-bao_general",
    "name": "Chỉ báo - Indicators",
    "slug": "chi-bao_general",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3961"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3961"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3960,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./chi-bao-indicators",
    "name": "Chỉ báo (Indicators)",
    "slug": "chi-bao-indicators",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3960"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3960"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3964,
    "count": 16,
    "description": "",
    "link": "https://vnrebates.info/./chi-bao-bien-dong-va-diem-ra-vao-vi-the",
    "name": "Chỉ báo biến động và điểm ra vào vị thế",
    "slug": "chi-bao-bien-dong-va-diem-ra-vao-vi-the",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3964"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3964"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3963,
    "count": 13,
    "description": "",
    "link": "https://vnrebates.info/./chi-bao-dong-luong",
    "name": "Chỉ báo động lượng",
    "slug": "chi-bao-dong-luong",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3963"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3963"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3966,
    "count": 6,
    "description": "",
    "link": "https://vnrebates.info/./chi-bao-khoi-luong",
    "name": "Chỉ báo khối lượng",
    "slug": "chi-bao-khoi-luong",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3966"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3966"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3962,
    "count": 9,
    "description": "",
    "link": "https://vnrebates.info/./chi-bao-xu-huong",
    "name": "Chỉ báo xu hướng",
    "slug": "chi-bao-xu-huong",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3962"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3962"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3931,
    "count": 9,
    "description": "",
    "link": "https://vnrebates.info/./chien-luoc-giao-dich-vang",
    "name": "Chiến lược giao dịch vàng",
    "slug": "chien-luoc-giao-dich-vang",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3931"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3931"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 4002,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./chung-chi-quy",
    "name": "Chứng chỉ quỹ",
    "slug": "chung-chi-quy",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/4002"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=4002"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3734,
    "count": 138,
    "description": "",
    "link": "https://vnrebates.info/./chung-khoan",
    "name": "Chứng khoán",
    "slug": "chung-khoan",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "1"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3734"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3734"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3693,
    "count": 36,
    "description": "",
    "link": "https://vnrebates.info/./special-topic",
    "name": "Chuyên đề đặc biệt",
    "slug": "special-topic",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3693"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3693"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3919,
    "count": 14,
    "description": "",
    "link": "https://vnrebates.info/./thi-truong-vang",
    "name": "Cơ bản về vàng và thị trường vàng",
    "slug": "thi-truong-vang",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3919"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3919"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3923,
    "count": 26,
    "description": "",
    "link": "https://vnrebates.info/./co-hoi-dau-tu",
    "name": "Cơ hội đầu tư",
    "slug": "co-hoi-dau-tu",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3923"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3923"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 4000,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./co-phieu",
    "name": "Cổ phiếu",
    "slug": "co-phieu",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/4000"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=4000"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3733,
    "count": 244,
    "description": "",
    "link": "https://vnrebates.info/./coins",
    "name": "Coin",
    "slug": "coins",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3733"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3733"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3906,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./cong-cu-giao-dich",
    "name": "Công cụ giao dịch",
    "slug": "cong-cu-giao-dich",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3906"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3906"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3932,
    "count": 4,
    "description": "",
    "link": "https://vnrebates.info/./cong-cu-ho-tro-giao-dich-vang",
    "name": "Công cụ hỗ trợ giao dịch vàng",
    "slug": "cong-cu-ho-tro-giao-dich-vang",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3932"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3932"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3987,
    "count": 4,
    "description": "Cuộc sống trading của các Forex trader hay những nhà đầu tư phố Wall có thật sự hào nhoáng như nhiều người tưởng tượng hay không? Họ có những góc khuất gì?",
    "link": "https://vnrebates.info/./cuoc-song-trading",
    "name": "Cuộc sống trading",
    "slug": "cuoc-song-trading",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "2"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3987"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3987"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3752,
    "count": 29,
    "description": "",
    "link": "https://vnrebates.info/./dau-tho",
    "name": "Dầu",
    "slug": "dau-tho",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3752"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3752"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 4005,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./dau-tu-bat-dong-san",
    "name": "Đầu tư bất động sản",
    "slug": "dau-tu-bat-dong-san",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/4005"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=4005"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 1572,
    "count": 122,
    "description": "",
    "link": "https://vnrebates.info/./dau-tu",
    "name": "Đầu tư tài chính",
    "slug": "dau-tu",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/1572"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=1572"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 4004,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./dau-tu-vang",
    "name": "Đầu tư vàng",
    "slug": "dau-tu-vang",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/4004"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=4004"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3918,
    "count": 6,
    "description": "",
    "link": "https://vnrebates.info/./cong-cu-dau-tu-vang",
    "name": "Đầu tư vàng vào đâu?",
    "slug": "cong-cu-dau-tu-vang",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3918"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3918"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3925,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./dau-tu-vang-vao-dau",
    "name": "Đầu tư vàng vào đâu?",
    "slug": "dau-tu-vang-vao-dau",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3925"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3925"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3956,
    "count": 4,
    "description": "",
    "link": "https://vnrebates.info/./day-trading",
    "name": "Day trading",
    "slug": "day-trading",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3956"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3956"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3940,
    "count": 15,
    "description": "",
    "link": "https://vnrebates.info/./dieu-kien-thi-truong",
    "name": "Điều kiện thị trường",
    "slug": "dieu-kien-thi-truong",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3940"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3940"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3948,
    "count": 8,
    "description": "",
    "link": "https://vnrebates.info/./ea-robot-forex",
    "name": "EA-Robot Forex",
    "slug": "ea-robot-forex",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3948"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3948"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 11,
    "count": 8,
    "description": "",
    "link": "https://vnrebates.info/./ethereum",
    "name": "Ethereum - ETH",
    "slug": "ethereum",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/11"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=11"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3971,
    "count": 4,
    "description": "",
    "link": "https://vnrebates.info/./eur",
    "name": "EUR",
    "slug": "eur",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3971"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3971"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3747,
    "count": 46,
    "description": "",
    "link": "https://vnrebates.info/./eur-usd",
    "name": "EUR/USD",
    "slug": "eur-usd",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3747"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3747"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 1445,
    "count": 2253,
    "description": "",
    "link": "https://vnrebates.info/./forex",
    "name": "Forex",
    "slug": "forex",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/1445"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=1445"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3970,
    "count": 4,
    "description": "",
    "link": "https://vnrebates.info/./gbp",
    "name": "GBP",
    "slug": "gbp",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3970"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3970"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3748,
    "count": 35,
    "description": "",
    "link": "https://vnrebates.info/./gbp-usd",
    "name": "GBP/USD",
    "slug": "gbp-usd",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3748"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3748"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3997,
    "count": 1,
    "description": "Cập nhật giá vàng trong nước và giá vàng quốc tế nhanh nhất, chính xác nhất hàng ngày",
    "link": "https://vnrebates.info/./gia-vang-hom-nay",
    "name": "Giá vàng hôm nay",
    "slug": "gia-vang-hom-nay",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3997"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3997"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3755,
    "count": 18,
    "description": "",
    "link": "https://vnrebates.info/./hang-hoa",
    "name": "Hàng hóa",
    "slug": "hang-hoa",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3755"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3755"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3969,
    "count": 5,
    "description": "",
    "link": "https://vnrebates.info/./jpy",
    "name": "JPY",
    "slug": "jpy",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3969"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3969"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3944,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./key",
    "name": "Key",
    "slug": "key",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3944"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3944"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3941,
    "count": 10,
    "description": "",
    "link": "https://vnrebates.info/./key-level",
    "name": "Key level",
    "slug": "key-level",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3941"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3941"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3,
    "count": 69,
    "description": "<span data-sheets-value=\"{&quot;1&quot;:2,&quot;2&quot;:&quot;Cập nhật liên tục các chương trình khuyến mãi hấp dẫn từ các Forex Broker như các khoản bonus tiền nạp, các cuộc thi giao dịch hay rút thăm trúng thưởng.&quot;}\" data-sheets-userformat=\"{&quot;2&quot;:12673,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:2,&quot;11&quot;:3,&quot;15&quot;:&quot;Calibri&quot;,&quot;16&quot;:11}\">Cập nhật liên tục các chương trình khuyến mãi hấp dẫn từ các Forex Broker như các khoản bonus tiền nạp, các cuộc thi giao dịch hay rút thăm trúng thưởng.</span>",
    "link": "https://vnrebates.info/./blog/khuyen-mai-forex-broker",
    "name": "Khuyến mãi Forex Broker",
    "slug": "khuyen-mai-forex-broker",
    "taxonomy": "category",
    "parent": 2,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "1"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "up": [
        {
          "embeddable": true,
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/2"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3737,
    "count": 1385,
    "description": "",
    "link": "https://vnrebates.info/./kien-thuc",
    "name": "Kiến thức",
    "slug": "kien-thuc",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3737"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3737"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3904,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./kien-thuc-chung-khac",
    "name": "Kiến thức chung khác",
    "slug": "kien-thuc-chung-khac",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3904"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3904"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3745,
    "count": 15,
    "description": "",
    "link": "https://vnrebates.info/./kien-thuc-chung-khoan",
    "name": "Kiến thức chứng khoán",
    "slug": "kien-thuc-chung-khoan",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3745"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3745"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3746,
    "count": 35,
    "description": "",
    "link": "https://vnrebates.info/./kien-thuc-dau-tu-tai-chinh",
    "name": "Kiến thức đầu tư tài chính",
    "slug": "kien-thuc-dau-tu-tai-chinh",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3746"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3746"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3743,
    "count": 170,
    "description": "",
    "link": "https://vnrebates.info/./kien-thuc-forex",
    "name": "Kiến thức forex",
    "slug": "kien-thuc-forex",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3743"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3743"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3744,
    "count": 70,
    "description": "",
    "link": "https://vnrebates.info/./kien-thuc-tien-dien-tu",
    "name": "Kiến thức tiền điện tử",
    "slug": "kien-thuc-tien-dien-tu",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3744"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3744"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 5,
    "count": 108,
    "description": "",
    "link": "https://vnrebates.info/./blog/kinh-nghiem-giao-dich-forex",
    "name": "Kinh nghiệm giao dịch Forex",
    "slug": "kinh-nghiem-giao-dich-forex",
    "taxonomy": "category",
    "parent": 2,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/5"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "up": [
        {
          "embeddable": true,
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/2"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=5"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3988,
    "count": 10,
    "description": "",
    "link": "https://vnrebates.info/./kinh-nghiem-thuc-chien",
    "name": "Kinh nghiệm thực chiến",
    "slug": "kinh-nghiem-thuc-chien",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3988"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3988"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3986,
    "count": 32,
    "description": "",
    "link": "https://vnrebates.info/./kinh-nghiem-trading",
    "name": "Kinh nghiệm trading",
    "slug": "kinh-nghiem-trading",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "2"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3986"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3986"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 10,
    "count": 2,
    "description": "",
    "link": "https://vnrebates.info/./litecoin",
    "name": "Litecoin - LTC",
    "slug": "litecoin",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/10"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=10"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3950,
    "count": 9,
    "description": "",
    "link": "https://vnrebates.info/./mau-hinh-gia",
    "name": "Mẫu hình giá",
    "slug": "mau-hinh-gia",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3950"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3950"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 4008,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./nang-luong",
    "name": "Năng lượng",
    "slug": "nang-luong",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "2"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/4008"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=4008"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3949,
    "count": 16,
    "description": "",
    "link": "https://vnrebates.info/./nen-nhat",
    "name": "Nến Nhật",
    "slug": "nen-nhat",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3949"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3949"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 4009,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./nguyen-lieu-cong-nghiep",
    "name": "Nguyên liệu công nghiệp",
    "slug": "nguyen-lieu-cong-nghiep",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "2"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/4009"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=4009"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3984,
    "count": 28,
    "description": "<span data-sheets-value=\"{&quot;1&quot;:2,&quot;2&quot;:&quot;Tổng hợp và cập nhật thường xuyên các nhận định đầu tư theo Timing Zone phiên giao dịch và góc nhìn Wyckoff đối với các cặp FX chính, Vàng, USD và BTC.&quot;}\" data-sheets-userformat=\"{&quot;2&quot;:12675,&quot;3&quot;:{&quot;1&quot;:0},&quot;4&quot;:{&quot;1&quot;:2,&quot;2&quot;:16777215},&quot;10&quot;:2,&quot;11&quot;:3,&quot;15&quot;:&quot;Calibri&quot;,&quot;16&quot;:11}\">Tổng hợp và cập nhật thường xuyên các nhận định đầu tư theo Timing Zone phiên giao dịch và góc nhìn Wyckoff đối với các cặp FX chính, Vàng, USD và BTC.</span>",
    "link": "https://vnrebates.info/./nhan-dinh",
    "name": "Nhận định",
    "slug": "nhan-dinh",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3984"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3984"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3933,
    "count": 18,
    "description": "",
    "link": "https://vnrebates.info/./nhap-mon-dau-tu-forex",
    "name": "Nhập môn đầu tư forex",
    "slug": "nhap-mon-dau-tu-forex",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3933"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3933"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 4010,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./nong-san",
    "name": "Nông sản",
    "slug": "nong-san",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "2"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/4010"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=4010"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 4003,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./phai-sinh",
    "name": "Phái sinh",
    "slug": "phai-sinh",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/4003"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=4003"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 4011,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./phan-tich-nhan-dinh-thi-truong",
    "name": "Phân tích - nhận định thị trường",
    "slug": "phan-tich-nhan-dinh-thi-truong",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "2"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/4011"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=4011"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3739,
    "count": 154,
    "description": "",
    "link": "https://vnrebates.info/./phan-tich-nhan-dinh",
    "name": "Phân tích &amp; nhận định",
    "slug": "phan-tich-nhan-dinh",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3739"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3739"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3754,
    "count": 12,
    "description": "",
    "link": "https://vnrebates.info/./phan-tich-nhan-dinh-chung-khoan",
    "name": "Phân tích &amp; nhận định chứng khoán",
    "slug": "phan-tich-nhan-dinh-chung-khoan",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3754"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3754"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3902,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./phan-tich-co-ban",
    "name": "Phân tích cơ bản",
    "slug": "phan-tich-co-ban",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3902"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3902"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3901,
    "count": 10,
    "description": "",
    "link": "https://vnrebates.info/./phan-tich-ky-thuat",
    "name": "Phân tích kỹ thuật",
    "slug": "phan-tich-ky-thuat",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3901"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3901"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3953,
    "count": 5,
    "description": "",
    "link": "https://vnrebates.info/./phong-cach-giao-dich",
    "name": "Phong cách giao dịch",
    "slug": "phong-cach-giao-dich",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3953"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3953"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3907,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./phong-cach-trade",
    "name": "Phong cách trade",
    "slug": "phong-cach-trade",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3907"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3907"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3978,
    "count": 19,
    "description": "<span data-sheets-value=\"{&quot;1&quot;:2,&quot;2&quot;:&quot;Cùng trader đánh giá plan tuần trước và lên kế hoạch plan tuần mới cho các cặp tiền giao dịch với sự giúp sức của phân tích Price Action, VSA kết hợp với Volume.&quot;}\" data-sheets-userformat=\"{&quot;2&quot;:12675,&quot;3&quot;:{&quot;1&quot;:0},&quot;4&quot;:{&quot;1&quot;:2,&quot;2&quot;:16777215},&quot;10&quot;:2,&quot;11&quot;:3,&quot;15&quot;:&quot;docs-Calibri&quot;,&quot;16&quot;:11}\">Cùng review lại plan tuần trước và lên kế hoạch plan tuần mới cho các cặp tiền giao dịch với sự giúp sức của phân tích Price Action, VSA kết hợp với Volume.</span>",
    "link": "https://vnrebates.info/./plan-tuan",
    "name": "Plan tuần",
    "slug": "plan-tuan",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3978"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3978"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3910,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./platform-giao-dich",
    "name": "Platform giao dịch",
    "slug": "platform-giao-dich",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3910"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3910"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3957,
    "count": 3,
    "description": "",
    "link": "https://vnrebates.info/./position-trading",
    "name": "Position trading",
    "slug": "position-trading",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3957"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3957"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3947,
    "count": 6,
    "description": "",
    "link": "https://vnrebates.info/./price-action",
    "name": "Price Action",
    "slug": "price-action",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3947"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3947"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3954,
    "count": 4,
    "description": "",
    "link": "https://vnrebates.info/./quan-ly-giao-dich-cua-pro-trader",
    "name": "Quản lý giao dịch của Pro trader",
    "slug": "quan-ly-giao-dich-cua-pro-trader",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3954"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3954"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3903,
    "count": 10,
    "description": "<span data-sheets-value=\"{&quot;1&quot;:2,&quot;2&quot;:&quot;Quản lý vốn là yếu tố rất quan trọng trong giao dịch. Cùng xem các phương pháp và nguyên tắc quản lý vốn Forex hiệu quả và an toàn nhất để giảm thiểu rủi ro.&quot;}\" data-sheets-userformat=\"{&quot;2&quot;:12673,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:2,&quot;11&quot;:3,&quot;15&quot;:&quot;Calibri&quot;,&quot;16&quot;:11}\">Quản lý vốn là yếu tố rất quan trọng trong giao dịch. Cùng xem các phương pháp và nguyên tắc quản lý vốn Forex hiệu quả và an toàn nhất để giảm thiểu rủi ro.</span>",
    "link": "https://vnrebates.info/./quan-ly-von",
    "name": "Quản lý vốn",
    "slug": "quan-ly-von",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "1"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3903"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3903"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3391,
    "count": 191,
    "description": "<span data-sheets-value=\"{&quot;1&quot;:2,&quot;2&quot;:&quot;Tổng hợp những bài đánh giá về các sàn giao dịch Forex và tiền điện tử tốt nhất, giúp nhà đầu tư chọn lựa được sàn uy tín và phù hợp với nhu cầu giao dịch.&quot;}\" data-sheets-userformat=\"{&quot;2&quot;:12673,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:2,&quot;11&quot;:3,&quot;15&quot;:&quot;Calibri&quot;,&quot;16&quot;:11}\">Tổng hợp những bài đánh giá về các sàn giao dịch Forex và tiền điện tử tốt nhất, giúp nhà đầu tư chọn lựa được sàn uy tín và phù hợp với nhu cầu giao dịch.</span>",
    "link": "https://vnrebates.info/./blog/review",
    "name": "Review Broker",
    "slug": "review",
    "taxonomy": "category",
    "parent": 2,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "1"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3391"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "up": [
        {
          "embeddable": true,
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/2"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3391"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3909,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./review-co-phieu",
    "name": "Review cổ phiếu",
    "slug": "review-co-phieu",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3909"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3909"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3979,
    "count": 17,
    "description": "<span data-sheets-value=\"{&quot;1&quot;:2,&quot;2&quot;:&quot;Review lệnh là chuỗi bài đánh giá các lệnh mà team VnRebates đã vào trong từng tuần. Từ đó, trader có thể áp dụng vào giao dịch thực tế để tối ưu hóa lợi nhuận.&quot;}\" data-sheets-userformat=\"{&quot;2&quot;:12673,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:2,&quot;11&quot;:3,&quot;15&quot;:&quot;Calibri&quot;,&quot;16&quot;:11}\">Review lệnh là chuỗi bài đánh giá các lệnh mà team VnRebates đã thực hiện trong từng tuần. Từ đó, trader có thể áp dụng vào giao dịch thực tế để tối ưu hóa lợi nhuận.</span>",
    "link": "https://vnrebates.info/./review-lenh",
    "name": "Review lệnh",
    "slug": "review-lenh",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3979"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3979"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 13,
    "count": 3,
    "description": "",
    "link": "https://vnrebates.info/./ripple",
    "name": "Ripple - XRP",
    "slug": "ripple",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/13"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=13"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3934,
    "count": 18,
    "description": "",
    "link": "https://vnrebates.info/./san-giao-dich-forex",
    "name": "Sàn giao dịch Forex",
    "slug": "san-giao-dich-forex",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3934"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3934"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3905,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./san-va-giao-dich-tren-san",
    "name": "Sàn và giao dịch trên sàn",
    "slug": "san-va-giao-dich-tren-san",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3905"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3905"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3943,
    "count": 4,
    "description": "",
    "link": "https://vnrebates.info/./scale-in-scale-out",
    "name": "Scale-in - Scale-out",
    "slug": "scale-in-scale-out",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3943"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3943"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3955,
    "count": 3,
    "description": "",
    "link": "https://vnrebates.info/./scalping",
    "name": "Scalping",
    "slug": "scalping",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3955"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3955"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3938,
    "count": 4,
    "description": "",
    "link": "https://vnrebates.info/./stoploss-va-take-profit",
    "name": "Stoploss và Take Profit",
    "slug": "stoploss-va-take-profit",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3938"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3938"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3958,
    "count": 3,
    "description": "",
    "link": "https://vnrebates.info/./swing-trading",
    "name": "Swing trading",
    "slug": "swing-trading",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3958"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3958"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3999,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./tai-chinh-ca-nhan",
    "name": "Tài chính cá nhân",
    "slug": "tai-chinh-ca-nhan",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "2"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3999"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3999"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3951,
    "count": 13,
    "description": "",
    "link": "https://vnrebates.info/./tam-ly-giao-dich",
    "name": "Tâm lý giao dịch",
    "slug": "tam-ly-giao-dich",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3951"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3951"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3917,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./test",
    "name": "test nhóm 1",
    "slug": "test",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "1"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3917"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3917"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 12,
    "count": 2,
    "description": "",
    "link": "https://vnrebates.info/./tether",
    "name": "Tether - USDT",
    "slug": "tether",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/12"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=12"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3959,
    "count": 4,
    "description": "",
    "link": "https://vnrebates.info/./thesis",
    "name": "Thesis",
    "slug": "thesis",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3959"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3959"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 4,
    "count": 288,
    "description": "<span data-sheets-value=\"{&quot;1&quot;:2,&quot;2&quot;:&quot;Các thông báo từ broker mới nhất sẽ được cập nhật liên tục. Xem ngay các broker bổ sung tính năng và sản phẩm mới, ra mắt khóa học hay chương trình khuyến mãi nào.&quot;}\" data-sheets-userformat=\"{&quot;2&quot;:12673,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:2,&quot;11&quot;:3,&quot;15&quot;:&quot;Calibri&quot;,&quot;16&quot;:11}\">Các thông báo từ broker mới nhất sẽ được cập nhật liên tục. Xem ngay các broker bổ sung tính năng và sản phẩm mới, ra mắt khóa học hay chương trình khuyến mãi nào.</span>",
    "link": "https://vnrebates.info/./blog/thong-bao-tu-broker",
    "name": "Thông báo từ Broker",
    "slug": "thong-bao-tu-broker",
    "taxonomy": "category",
    "parent": 2,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "1"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/4"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "up": [
        {
          "embeddable": true,
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/2"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=4"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3976,
    "count": 85,
    "description": "Tổng hợp các plan giao dịch, review lệnh đối với các cặp tiền FX chính với phân tích vào lệnh thực chiến theo 3 bước SMS: Structure, Momentum sóng và Setup.",
    "link": "https://vnrebates.info/./thuc-chien-nghe-trading",
    "name": "Thực chiến nghề Trading",
    "slug": "thuc-chien-nghe-trading",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "2"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3976"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3976"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 1,
    "count": 219,
    "description": "",
    "link": "https://vnrebates.info/./tin-forex",
    "name": "Tin Forex",
    "slug": "tin-forex",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/1"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=1"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3977,
    "count": 12,
    "description": "",
    "link": "https://vnrebates.info/./tin-hieu",
    "name": "Tín hiệu",
    "slug": "tin-hieu",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3977"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3977"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 6,
    "count": 6,
    "description": "",
    "link": "https://vnrebates.info/./blog/tin-hieu-giao-dich-forex",
    "name": "Tín hiệu giao dịch Forex",
    "slug": "tin-hieu-giao-dich-forex",
    "taxonomy": "category",
    "parent": 2,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/6"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "up": [
        {
          "embeddable": true,
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/2"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=6"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3736,
    "count": 681,
    "description": "<span data-sheets-value=\"{&quot;1&quot;:2,&quot;2&quot;:&quot;Cập nhật liên tục tin tức thị trường quan trọng ảnh hưởng đến quyết định giao dịch của trader. Cùng xem chính sách mới của Fed là gì? Chỉ số CPI, PPI của Mỹ ra sao?&quot;}\" data-sheets-userformat=\"{&quot;2&quot;:12673,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:2,&quot;11&quot;:3,&quot;15&quot;:&quot;Calibri&quot;,&quot;16&quot;:11}\">Cập nhật liên tục tin tức quan trọng ảnh hưởng đến quyết định giao dịch của trader. Cùng xem chính sách mới của Fed là gì? Chỉ số CPI, PPI của Mỹ ra sao...</span>",
    "link": "https://vnrebates.info/./tin-tuc",
    "name": "Tin tức",
    "slug": "tin-tuc",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "1"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3736"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3736"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3741,
    "count": 16,
    "description": "",
    "link": "https://vnrebates.info/./tin-tuc-chung-khoan",
    "name": "Tin tức chứng khoán",
    "slug": "tin-tuc-chung-khoan",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3741"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3741"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3742,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./tin-tuc-dau-tu-tai-chinh",
    "name": "Tin tức đầu tư tài chính",
    "slug": "tin-tuc-dau-tu-tai-chinh",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3742"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3742"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3740,
    "count": 2,
    "description": "",
    "link": "https://vnrebates.info/./tin-tuc-tien-dien-tu",
    "name": "Tin tức tiền điện tử",
    "slug": "tin-tuc-tien-dien-tu",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3740"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3740"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3991,
    "count": 15,
    "description": "",
    "link": "https://vnrebates.info/./trade-quy",
    "name": "Trade quỹ",
    "slug": "trade-quy",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3991"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3991"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 4001,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./trai-phieu",
    "name": "Trái phiếu",
    "slug": "trai-phieu",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/4001"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=4001"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3928,
    "count": 4,
    "description": "",
    "link": "https://vnrebates.info/./vang-va-cac-kenh-dau-tu-khac",
    "name": "Tương quan giữa vàng và các kênh đầu tư khác",
    "slug": "vang-va-cac-kenh-dau-tu-khac",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "1"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3928"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3928"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3995,
    "count": 0,
    "description": "Cập nhật tỷ giá đồng bảng Anh nhanh nhất, mới nhất hàng ngày từ Ngân hàng Nhà nước, tỷ giá đồng bảng Anh so với USD",
    "link": "https://vnrebates.info/./ty-gia-bang-anh",
    "name": "Tỷ giá bảng Anh",
    "slug": "ty-gia-bang-anh",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3995"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3995"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3994,
    "count": 1,
    "description": "Cập nhật tỷ giá đồng đô la Úc hàng ngày, tỷ giá AUD so với USD và các đồng tiền khác",
    "link": "https://vnrebates.info/./ty-gia-dola-uc",
    "name": "Tỷ giá đô la Úc",
    "slug": "ty-gia-dola-uc",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3994"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3994"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3993,
    "count": 0,
    "description": "Cập nhật hàng ngày tỷ giá đồng tiền chung châu Âu Euro trong nước và quốc tế",
    "link": "https://vnrebates.info/./ty-gia-euro",
    "name": "Tỷ giá Euro",
    "slug": "ty-gia-euro",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3993"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3993"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3992,
    "count": 27,
    "description": "Cập nhật tỷ giá USD từ Ngân hàng Nhà nước, các ngân hàng thương mại lớn và tỷ giá USD thế giới nhanh nhất và chính xác nhất hàng ngày",
    "link": "https://vnrebates.info/./ty-gia-usd",
    "name": "Tỷ giá USD",
    "slug": "ty-gia-usd",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3992"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3992"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3996,
    "count": 0,
    "description": "Cập nhật tỷ giá Yên Nhật trong nước và tỷ giá JPY/USD hàng ngày nhanh nhất",
    "link": "https://vnrebates.info/./ty-gia-yen-nhat",
    "name": "Tỷ giá Yên Nhật",
    "slug": "ty-gia-yen-nhat",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3996"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3996"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3967,
    "count": 6,
    "description": "<span data-sheets-value=\"{&quot;1&quot;:2,&quot;2&quot;:&quot;USD là đồng tiền mạnh nhất thế giới, có ảnh hưởng lớn đến kinh tế toàn cầu. Các yếu tố tác động đến đồng USD là gì? Trader cần lưu ý gì khi giao dịch với đồng USD?&quot;}\" data-sheets-userformat=\"{&quot;2&quot;:12673,&quot;3&quot;:{&quot;1&quot;:0},&quot;10&quot;:2,&quot;11&quot;:3,&quot;15&quot;:&quot;Calibri&quot;,&quot;16&quot;:11}\">USD là đồng tiền mạnh nhất thế giới, có ảnh hưởng lớn đến kinh tế toàn cầu. Các yếu tố tác động đến đồng USD là gì? Cần lưu ý gì khi giao dịch với đồng USD?</span>",
    "link": "https://vnrebates.info/./usd",
    "name": "USD",
    "slug": "usd",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3967"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3967"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3756,
    "count": 39,
    "description": "",
    "link": "https://vnrebates.info/./us-dollar-index",
    "name": "USD index",
    "slug": "us-dollar-index",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3756"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3756"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3751,
    "count": 32,
    "description": "",
    "link": "https://vnrebates.info/./usd-cad",
    "name": "USD/CAD",
    "slug": "usd-cad",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3751"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3751"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3911,
    "count": 13,
    "description": "",
    "link": "https://vnrebates.info/./usd-chf",
    "name": "USD/CHF",
    "slug": "usd-chf",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3911"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3911"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3749,
    "count": 36,
    "description": "",
    "link": "https://vnrebates.info/./usd-jpy",
    "name": "USD/JPY",
    "slug": "usd-jpy",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3749"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3749"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3753,
    "count": 91,
    "description": "",
    "link": "https://vnrebates.info/./xau-usd",
    "name": "Vàng - XAUUSD",
    "slug": "xau-usd",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "1"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3753"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3753"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 4007,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./vang-va-kim-loai",
    "name": "Vàng và kim loại",
    "slug": "vang-va-kim-loai",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "2"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/4007"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=4007"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3939,
    "count": 4,
    "description": "",
    "link": "https://vnrebates.info/./volume",
    "name": "Volume",
    "slug": "volume",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": [
      
    ],
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3939"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3939"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3972,
    "count": 0,
    "description": "",
    "link": "https://vnrebates.info/./wti",
    "name": "WTI",
    "slug": "wti",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null,
      "config_category_group": "3"
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3972"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3972"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  },
  {
    "id": 3915,
    "count": 1,
    "description": "",
    "link": "https://vnrebates.info/./xu-huong-defi",
    "name": "Xu hướng defi",
    "slug": "xu-huong-defi",
    "taxonomy": "category",
    "parent": 0,
    "meta": [
      
    ],
    "acf": {
      "sologan": "",
      "banner_1920": null,
      "banner_1440": null,
      "banner_mobile": null,
      "banner_footer_1920": null,
      "banner_footer_1440": null,
      "banner_footer_mobile": null
    },
    "_links": {
      "self": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories/3915"
        }
      ],
      "collection": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/categories"
        }
      ],
      "about": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/taxonomies/category"
        }
      ],
      "wp:post_type": [
        {
          "href": "https://vnrebates.info/wp-json/wp/v2/posts?categories=3915"
        }
      ],
      "curies": [
        {
          "name": "wp",
          "href": "https://api.w.org/{rel}",
          "templated": true
        }
      ]
    }
  }
]