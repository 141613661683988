import { request } from '../utils/request';

export const getStorageFiles = ({ page, size }) =>
  request({
    url: '/storage-file',
    method: 'GET',
    params: {
      page,
      size,
    },
  });

export const deleteStorageFileById = ({ storageFileId, documentName, folderId }) =>
  request({
    url: '/storage-file/deleteStorageFileById',
    method: 'DELETE',
    params: { storageFileId, documentName, folderId },
  });

export const uploadDocument = (folderId, formData) =>
  request({
    url: '/storage-file/uploadDocument',
    method: 'POST',
    params: { folderId: folderId },
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const updateStorageFile = (
  formData,
  { storageFileId, fileName, folderId }
) =>
  request({
    url: '/storage-file',
    method: 'PUT',
    params: { storageFileId, fileName, folderId },
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const getHistoryChange = ({ storageFileId, page, size }) =>
  request({
    url: `/storage-file/getHistoryChange/${storageFileId}`,
    method: 'GET',
    params: { page, size },
  });

export const getFilesOfFolder = ({ folderId, page, size }) =>
  request({
    url: '/storage-file/getFilesOfFolder',
    method: 'GET',
    params: { folderId, page, size },
  });

export const uploadAvatar = (id, formData) =>
  request({
    url: '/storage-file/uploadAvatar',
    method: 'POST',
    params: { id: id },
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  
export const uploadTraderCommentImages = (id, formData) =>
  request({
    url: '/storage-file/uploadTraderCommentImages',
    method: 'POST',
    params: { id: id },
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });