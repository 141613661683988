import axios from "axios";
import { WP_DOMAIN } from "src/constants";

export const request = async ({
  // host = "", //temp
  // prefix,
  url = "",
  method = "get",
  params,
  data,
  headers = {},
  ...props
}) => {
 
  try {
    const result = await axios({
      url: `${WP_DOMAIN}${url}`,
      method,
      data,
      params,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        ...headers,
      },
      ...props,
    });
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
