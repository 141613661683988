import LogoVnrWhiteEn from "@components/Icons/logoVnrWhite_en";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  home: {
    title: 'VnRebates - In-depth Knowledge on Trading in Forex, Crypto and Commodity Market',
    description: `Asia's #1 Forex Cashback Ecosystem: Free Intensive Trading Courses, Trading Signals and many other accumulating rewards`,
    logo: '/icons/logoVnrHomePage_en.svg',
    logoWhiteFooter: <LogoVnrWhiteEn width={200} />,
    logoWhiteFooterMobile: <LogoVnrWhiteEn width={150} />,
    forexBrokerListTitle: 'Rebates Forex Broker',
    tradeFundListTitle: 'Trade Fund',
    cryptoBrokerListTitle: 'Rebates Crypto Broker',
    viewMore: 'View more',
    topBrokerPost: 'Top Broker List',
    paymentMethod: 'REBATES PAYMENT METHOD',
    homeSliderForeign: 'https://agileops-gafv-dev-videos.s3.amazonaws.com/bannerAds/slider%20en.jpg',
    bannerAcademy: "https://agileops-gafv-dev-videos.s3.amazonaws.com/bannerAds/academy%20en.jpg",
    bannerSignal: "https://agileops-gafv-dev-videos.s3.amazonaws.com/bannerAds/signal%20en.jpg"
  },
  dashboard: {
    rewardTreeTitle: 'Membership benefit at VnRebates',
    title: 'VnRebates Membership Benefits',
    hello: 'Hello',
    friend: 'friend',
    totalRebate: 'Total rebate:',
    rebate: 'Rebate',
    ctv: 'Join our partner program and enjoy up to 50% commission rate',
    benefitDescription: 'Additionally, members of VnRebates are entitled to the following accumulated rewards:',
    quote: 'We aspire to keep Traders company in the pursuit of sustainable values and profits.',
    rewardPathTitle: 'Accumulated Rewards',
    reward: 'Reward',
    goal: 'Goal',
    titleRebateLink: 'Link your account to receive rebates',
    linkedHistory: 'Link History',
    noLink: `You haven't linked any account yet`,
    addBrokerAccountSuccess: 'Success',
    pleaseSelectBroker: 'Please select broker',
    selectBroker: 'Select broker',
    accountNumber: 'Account Number',
    addLink: 'Link my account',
    confirm: 'I verify VnRebates as my IB',
    paymentMethod: 'Receive Rebates',
    caution: 'Caution',
    cautionNote1: 'VnRebates only offers rebates to successfully linked accounts.',
    cautionNote2: 'To receive rebates, please make sure you submit valid personal information and methods of payment prior to the 5th day of a month.',
    cautionNote3: 'Failure to submit all required information will result in your loss of monthly rebates.',
    payRebatesDescription: 'To receive rebates, please make sure you submit valid personal information and methods of payment prior to the 5th day of a month.',
    lastMonthRebate: `Last month's rebate`,
    totalReceivedCommission: 'Total Commission',
    totalReceivedRebates: 'Total Rebate',
    statistical: `Recap of last 12 months' rebates`,
    walletEmail: 'Ngan luong wallet email',
    agreed: 'Add',
    agencyRegisterTitle: 'Client Referral (For Partners Only)',
    agencyRegister: 'Register',
    agencyRegisterParterRequired: 'You need to sign in to use this feature!',
    activities: 'Your activity',
    noActivitives: 'No activity yet',
    bannerRebates: "https://agileops-gafv-dev-videos.s3.amazonaws.com/bannerAds/en-dasboard-rebate-img.png",
    agencyViewStat: 'View your customer statistics and commissions',
    agencyLink: 'Referral link',
    copyAgencyLink: 'Copy link',
    agencyCode: 'Referral code',
    traderPath: `Trader's path`,
    manageRebates: 'Manage Rebates',
    lastMonthCompare: 'for last month',
    ctvMobile: 'Registered agency get 50% commission',
    activeDescription: 'Paid Rebates',
    updateAccount: 'Update account'
  },
  homepageBrokerList: {
    openAccount: 'Sign Up',
    rebateRate: 'Rebate: ',
    viewMore: 'View more',
    feeReduction: 'Fee deduction',
    noted: 'Note',
    exchange: 'Decentralized exchange',
    maxLeverage: 'Max leverage',
    transactionFees: 'Transaction fees'
  },
  avatarDropdown: {
    profile: 'PERSONAL INFORMATION',
    dashboard: 'DASHBOARD',
    logout: 'LOG OUT'
  },
  menu: {
    featurePost: 'FEATURED',
    topicTreeName: 'FOREX KNOWLEDGE TREE',
    loginButton: 'Login',
    dashboard: 'Dashboard',
    logout: 'Logout',
    profile: 'Personal Information',
    signalChanel: 'Signal channel',
    forex: 'FOREX'
  },
  layout: {
    breadcrumb: 'Home'
  },
  brokerList: {
    forex: 'LIST OF TRUSTED CASHBACK FOREX REBATE BROKERS',
  },
  radioBroker: {
    note: 'All your rights and benefits are reserved when signing up for rebates with the ensuing Brokers',
    forex: 'Forex Broker',
    crypto: 'Crypto Broker',
    fund: 'Funded Trader Program',
    securities: 'Securities Company',
    merchandise: 'Merchandising Company',
  },
  forexInfo: {
    reviewPost: 'Review',
    guild: 'How to make deposits and withdrawals in',
    rebateRate: 'Rebate Rate',
    viewDetail: 'View details',
    gold: 'Gold',
    fx: 'FX',
    index: 'Index',
    oil: 'Oil',
    crypto: 'Crypto',
    receiveRebates: 'Receive Rebates',
    register: 'Register',
    establish: 'Established in',
    regulated: 'Regulated by',
    viewRegulated: 'Regulated by',
    model: 'Model',
    deposit: 'Make a deposit',
    minDeposit: 'Minimum Deposit',
    minVolume: 'Minimum Volume',
    maxLeverage: 'Maximum Leverage',
    spread: 'Spread',
    product: 'Support',
    fee: 'Trading fee rate',
    openAccount: 'Open account',
    pageTitle: 'List of trusted and licensed Forex Brokers offering best Rebate Service - VnRebates',
    pageDescription: `Most comprehensive reviews on world's most trusted Forex Brokers offering best rebate rates such as Exness, ICmarkets, XM, XTB, XTB, etc. by VnRebates`, 
  },
  cryptoPage: {
    title: 'List of trusted Crypto Brokers - VnRebates',
    description: 'All-inclusive list of trusted Crypto Brokers offering most favorable trading fee deduction rates'
  },
  tradeFund: {
    title: 'List of most trusted Forex Funded Trader Programs in the world - VnRebates',
    description: 'Exhaustive list of trusted Forex Funded Trader Programs with affordable fees and best profit share rates',
    reviewPost: 'Review',
    year: '',
    payoutRatio: 'Payout Ratio',
    allowBalance: 'Allocated Balanc',
    subscribeFee: 'Subscription Fee',
    dailyDrawdown: 'Daily Drawdown',
    maxDrawdown: 'Max Drawdown',
    newsTrading: 'News Trading',
    demo: 'Demo',
    vietnameseSupport: 'Vietnamese Support'
  },
  slickBroker: {
    text: 'Open account and receive rebate of'
  },
  footer: {
    social: 'SOCIAL MEDIA',
    category: 'CATEGORY',
    riskDisclaimer: `Risk Warning: Forex, Futures and Options Trading, though lucrative, are not without risks. High leverage can be as devastating as it is profitable. You are advised to come to terms with and brace yourself for such risks. Due to its high-risk nature, Forex trading is not everyone's cup of tea. We only provide investors with trading-related information. Last but not least, it is essential to bear in mind that no broker or trading strategy is infallible in the long run.`,
    license: 'Social Network License: To be issued',
    advertising: 'FOR ADVERTISING',
    copyRight: 'COPYRIGHT',
    copyRightReserve: '. ALL RIGHTS RESERVED'
  },
  awardTarget: {
    becameMember: 'Sign up VnRebates Membership',
    awardAchieved: 'Achieved',
    login: 'Login',
    startHere: 'Click here',
    airTagRequire: 'Accumulate a total rebate of 1,000$',
    airPodRequire: 'Accumulate a total rebate of 4,000$',
    smartWatchRequire: 'Accumulate a total rebate of  14,000$',
    macbookRequire: 'Accumulate a total rebate of 40,000$',
    shRequire: 'Accumulate a total rebate of 100,000$'
  },
  profile: {
    title: 'Since VnRebates offers a plethora of Financial Investment services, you are advised to submit valid personal information to receive professional consultations free of charge. ',
    name: 'Name',
    phone: 'Phone number',
    email: 'Email',
    province: 'Province',
    servicesQuestion: 'Please select your desired service(s)!',
    martkets: 'I am interested in the following broker(s):',
    buttonText: 'RECEIVE FREE SERVICE',
    guildText: 'Sign up to receive "4 Video Courses" (WORTH $800) for FREE from VnRebates'
  },
  videoAcademy: {
    breadcrumbAcademy: 'Course',
    title: 'VSA & Volume Master - Swim with the Sharks Course',
    description: 'VSA & Volume Master - Swim with the Shark Course by VnRebates is the culmination of years of knowledge, experience and analysis of expert traders from VnRebates itself',
    priceText: 'Purchase for',
    price: '$200 (4.600.000VND)',
    paymentMethod: 'Methods of Payment',
    banking: 'Bank Transfer',
    purchase: 'Learn now',
    viewing: 'Current Course',
    viewed: 'Watched',
    free: 'Free',
    view: 'View',
    test: 'Review Chapter',
    chatSupport: 'Chat support now',
    supportOpenAcademy: 'Contact support VnRebates to receive the course for free'
  },
  logoSponsor: {
    text: 'Co-sponsored by:',
    openAccount: 'Register'
  },
  courseInAcademy: {
    title: 'ALL COURSES',
    nameCourse1: "VSA & VOLUME MASTER",
    nameCourse2: "Concise Course SMC",
    nameCourse3: "SUPPLY & DEMAND",
    nameCourse4: "WYCKOFF 2.0",
    nameCourse5: "SUPPLY & DEMAND 2.0",
    leveCourse1: `Swim with the Sharks`,
    leveCourse2: "Tracking Smart Money",
    leveCourse3: "Understanding the FORCE to use FORCE",
    leveCourse4:  "The Practicality of Modern Wyckoff",
    leveCourse5:  "Dụng lực đúng vùng, giá đi tức thời",
    indexCourse1: "Video Course 1",
    indexCourse2: "Video Course 2",
    indexCourse3: "Video Course 3",
    indexCourse4: "Video Course 4",
    indexCourse5: "Video Course 5"
  },
  popupLogin: {
    benefitTitle: `"Sign in and Receive following benefits"`,
    benefitName1: '4 Trading Courses from Basic to Advanced (Price Action, Psychology & Capital Management, Volume & VSA)',
    benefitName2: 'Trade Signal Channels for 4 markets (Stocks, Commodities, Forex, Crypto)',
    benefitName3: 'Upwards of 40% additional rebates regardless of linked broker',
    benefitName4: 'Other Enticing Rewards',
    or: 'or',
    textBack: 'Back to Login',
    login: 'Sign in',
    register: 'Register',
    loginEmail: 'Email',
    loginPassword: 'Password',
    loginRePassword: 'Re-enter Password',
    agencyCode: 'Referral Code',
    registerAccount: 'Register',
    forgotPassword: 'Forgot Password?',
    checkboxText: 'I agree to receive e-mail notifications on offers and educational contents from VnRebates'
  },
  popupRegisterAgency: {
    title: 'COLLABORATOR AGREEMENTS',
    agreed: 'Agree to ensuing terms and become our collaborator',
    benefit: '1. Benefits:',
    benefit1: 'Collaborators receive commission equivalent to 50% of rebates offered to referred customers.',
    benefit2: 'Collaborators will receive commission and rebates simultaneously prior to the 5th day of a month.',
    benefit3: 'Collaborators are entitled to pertinent images, referral links, resources and services of VnRebates to faciliate the promotion and advertisement of our services to potential customers and markets.',
    benefit4: 'Collaborators will be provided information on latest offers from Brokers, all types of accounts, rebate rates, ...',
    benefit5: 'Should customer wish to change collaborator, referral code and commission for new collaborator will be effective in ensuing month.',
    responsibilities: '2. Your responsibilities:',
    responsibilities1: 'Collaborators must proactively advertise and promote our rebate service to reach out for potential customers and markets.',
    responsibilities2: 'Collaborators must under no circumstances use erroneous information to advertise our services.',
    responsibilities3: 'Collaborators must under no circumstances tarnish our credibility by exploiting our image and reputation for unlawful conducts.',
    responsibilities4: `Collaborators must take full responsibilities in resolving customers' conflicts with Brokers.`,
    ourResponsibilities: '3. Our responsibilities:',
    ourResponsibilities1: 'VnRebates must remit rebates to customers and collaborators in accordance with current regulations on commission of each Broker',
    ourResponsibilities2: 'VnRebates serves as an intermediary between customers, collaborators and Brokers in resolving potential conflicts',
    ourResponsibilities3: 'VnRebates takes no responsibilities (including but not limited to) in resolving conflicts between customers and referred Brokers.',
    termination: '4. Termination of collaboration:',
    terminationText: `Should collaborators violate above mentioned terms, undermining customers' benefits and those of VnRebates as well as our image, collaborative partnership will be terminated at once`,
    yes: 'I agree',
    no: 'No'
  },
  tutorialTranferIBModal: {
    title: 'Account Information Submitted',
    text1: 'Thank you! VnRebates has received your account information and your desired broker to receive rebate. ',
    text2: 'Please proceed to contact our Support below to finalize the validation process',
    support: 'Telegram Support'
  },
  buttonTelegram: 'Chat with us',
  roadToPro: {
    title: 'Embark on Courses on Forex Trading to become Pro Trader',
    description: 'Road to Pro Trader is rendered effortless thanks to Forex Courses devised by seasoned Forex trading masters with assistance of other financial channels of VnRebates'
  },
  rebatePaymentMethod: {
    signUpButton: 'SIGNUP FOR FREE',
    nganluongWallet: 'Ngan luong Wallet',
    usdtWallet: 'USDT Wallet',
    direct: 'Direct to Trading Account'
  },
  referrals: {
    seoTitle: 'Referral | VnRebates',
    title: 'OFFER: "1 Referral - 2 Rebates"',
    description: 'Earn passive incomes by promoting rebate services for Forex, stock, commodity and derivative trading purveyed by VnRebates ',
    start: 'Start Now',
    titleBenefit: 'About our referral program',
    roleDescription: `Refer customers to VnRebates via <b>PERSONAL REFERRAL LINK</b> to receive commission in <b>CASH</b> whenever customers trade or refer another customer. Deal is available for <b>LIFETIME</b> so long as customers' accounts are in effect`,
    referralFriends: 'HOW MUCH MONEY WILL I RECEIVE?',
    firstReason1: 'Referred customers receives rebates equivalent to 40% of Back Commission paid to VnRebates by Brokers',
    firstReason2: 'You will receive commission equivalent to 50% of Rebates whose referred customers receive',
    firstReason3: 'Should referred customers refer another customer, you will receive additional commission equivalent to 8.75% of Rebates such customer receives',
    referralFriendsHow: 'HOW WILL I RECEIVE PAYMENT?',
    secondReason1: 'Total amount of commission will be tallied prior to 5th day of each month',
    secondReason2: 'Customers will receive commission and rebates from personal trading activities simultaneously prior to 5th day of each month',
    secondReason3: 'Methods of Payment: Nganluong Wallet',
    criteriaWrapper: 'Our criteria in choosing Brokers for cooperation ',
    criteriaList: 'International Brokers under regulation of trusted regulatory bodies such as FCA, FSA, CySEC, etc.',
    criteriaRequire: 'Brokers with auspicious trade offers for diverse types of accounts to cater for sundry trading strategies of various traders, especially Pro traders',
    criteriaTranslate: 'Brokers offering meticulous and professional support in Vietnamese',
    guildTitle: 'Guides to Referral Registration',
    register: 'Sign up VnRebates Membership',
    registerGuild: 'Click <strong>“Sign in”</strong> on upper right corner to sign up VnRebates Membership',
    login: 'Sign in',
    agencyRegister: 'Sign up Collaborative Partnership with VnRebates',
    agencyRegisterGuild: 'Subsequent to sign-in, access Profile then scroll down until Referral section shows up. Scour section for “Sign up Collaborative Partnership” option then click it to become our collaborator and receive your personal referal link and code',
    step3: 'Refer customers to receive commission',
    step3Guild: 'Refer customers to rebate services purveyed by VnRebates via personal referral link and code to receive commission',
    step3GuildMore: 'To monitor referred customers and monthly commission',
    here: 'click here',
    step3GuildMoreAndMore: 'Sign up Nganluong Wallet to receive commission',
    step4: 'Commence Referral',
    contact: 'Contact us in case of further inquiries',
    principle: 'VnRebates operate on ensuing principles',
    principleQuote: 'If you want to go fast, go alone. If you want to go far, go together',
    principleEnding: ' We seek to serve as a nexus of connection and cooperation for the IB community in the mutual pursuit of values while securing sustainable benefits for your referred customers.'
  },
  brokerOpenAccountInstruction: {
    step1: 'Click on the option below to Sign up',
    step2: `Submit personal information as follows "Name - MT4/MT5 Account Number - Broker" via our <a href="https://www.facebook.com/Vnrebates" target="_blank"> Fanpage </a> or <a href="https://t.me/vnrebatessupport" target="_blank">Support Telegram </a> for validation`,
    step3: 'Commence trading & receive monthly rebates from VnRebates',
    fund: `Free consultation as to how to select felicitous fund is available via <a href="https://www.facebook.com/Vnrebates" target="_blank"> Fanpage </a> or <a href="https://t.me/vnrebatessupport" target="_blank">Support Telegram </a>`
  },
  landingPageRebates: {
    seoTitle: 'Financial Transaction Rebate Service - VnRebates',
    header: ``,
    subHeader: `<span style="font-size: 28px">Do you know that while introducing <br className="hiddenDesktop" /> brokers (IBs) receive commissions for their recommendations</span>`,
    headerContent1: `receive <b>NONE</b> of such money?`,
    headerContent2: `This loss of money must come to an <b>END</b>`,
    readMore: 'Find out more',
    subHeaderMobile: `<span style="font-size: 28px">Do you know that while introducing <br className="hiddenDesktop" /> brokers (IBs) receive commissions for their recommendations receive <b>NONE</b> of such money?</span>`,
    mobileHeaderContent1: ``,
    mobileHeaderContent2: ` This loss of money must <br /> come to an <b>END</b>`,
    titleSection: `What is <b>Financial Transaction Rebate Service</b> (Rebates)? `,
    sectionText1: 'To put it simply, if introducing brokers (IBs) of VNRebates refers you to a securities, merchandise transaction or forex, crypto broker, we will receive a commission from that broker whenever you make a transaction.',
    sectionText2: `After that, we will refund you <b>up to 80%</b> the commission. That is how Rebates works.`,
    titleSection1: 'What does this mean? This means',
    itemList1: 'The more you trade - The more you gain',
    itemList2: 'You will earn passive income',
    itemList3: 'Trading conditions are 100% similar to those of an account opened directly with a broker.',
    itemList4: 'Transaction fee will be reimbursed',
    itemList5: 'The greater the trading volume, the more the rebates - Regardless of the outcome of your trade orders',
    itemList6: 'You will receive rebates for all kinds of transactions ranging from securities, merchandise to Forex or crypto.',
    brokerRebates: 'Rebate Rate of Brokers',
    brokerRebatesDescription: 'Full List of Brokers with cashback rebate programs',
    forexList: 'List of Forex Brokers',
    cryptoList: 'List of Crypto Brokers',
    securitiesList: 'List of Securities Broker',
    merchandiseList: 'List of Merchandise Broker',
    titleSection2: 'Register to receive rebates',
    step1: 'Sign-up VnRebates Membership',
    step2: `Click <b>“Sign in”</b> on the upper right corner to become a member of VnRebates`,
    login: 'Sign in',
    step3: 'Open Your Trading Account',
    step3Guild: 'Choose the appropriate exchange from the following List of Brokers and Open your Trading Account',
    brokerList: 'List of Brokers',
    step4: 'Request Rebates',
    step4Guild: 'After opening an account with a broker and verifying your ID, navigate back to the Dashboard by clicking your profile picture',
    step4Guild1: 'Request rebates for your desired Broker and wait for us to confirm your request',
    step5: 'Add Method of Payment',
    step5Guild: 'At the moment, VnRebates only accepts payments in Ngan Luong. If you do not have an account, please register a NganLuong Wallet account here.',
    here: 'here',
    emailMethod: 'Fill in the Method of Payment section with your Email',
    step6: 'Start Trading',
    step6Guild: `Following the completion of the above steps, you can start trading. <br />
    VnRebates will sum up and proceed to rebate you before the fifth day of each month`,
    step6Guild1: `We offer rebates regardless of whether you have already <strong>Opened an account</strong> with the above Brokers or not`,
    contact: 'Should any question arises, please contact us at:',
    fanpage: 'Fanpage Support',
    telegram: 'Telegram Support',
    trustUs: 'We aim to provide Vietnamese Traders with the most professional Rebate Service on the principle that your interests are our interests.',
    trustUs1: 'Our service, while ensuring that you receive rebates, is completely free of charge. We wish you success so that our companionship will be unremitting.',
    brokerAccount: 'Trading account at Broker',
    product: 'Product',
    currency: 'Currency (EUR/USD)',
    gold: 'Gold',
    derivative: 'Derivative',
    merchandise: 'Merchandise (Coffee bean, oil, …)',
    derivativeSecurities: 'Derivative security',
    volume: 'Trading volume per month',
    unit: 'Unit',
    contract: 'Contract',
    spreadRate: 'Share to spread ratio',
    spread: 'Spread',
    vnrCommission: 'Brokerage Fee VnRebates receive',
    sum: 'Total',
    customerCommission: 'Rebate Customer receive',
    trustedUs: 'Trusted us',
    exnessCurrency: '370$',
    binance: '120$',
    vps: '27.83$',
    sumTotal: '887.83$',
    sumRebates: '355.13$',
    spreadCurrencyExness: '100$',
    spreadGoldExness: '200$'
  },
  popupTradingAcademyVideo: {
    buy: 'To buy now or receive FREE',
    title: 'BUY NOW VIDEO COURSE',
    pleaseInbox: 'please inbox support below'
  },
  controlFooter: {
    prevLesson: 'PREVIOUS LESSON',
    nextLesson: 'NEXT LESSON'
  }
}