import ModalHook, { useModal } from '@components/ModalHook';
import VnrLink from '@components/VnrLink';
import { Col,Row } from 'antd';
import React from 'react';

import styles from "./PopupUserSentInformation.module.scss";

export const MODAL_ID = 'POPUP_USER_SENT_INFORMATION';
const thankYouImage = '/images/thank you mail-11.jpg';

const PopupUserSentInformation = () => {
  const [modalData,, hidePopup] = useModal(MODAL_ID);
  const text = modalData?.data?.text || '';

  return (
    <ModalHook 
      id={MODAL_ID} 
      maskClosable={false} 
      onCancel={hidePopup}
      className={styles['popup-perform-target-reward']}
      style={{top: 40}}
      width="600px"
    >
      <div className={styles['content-wrapper']}>
        <Row className={styles['header']} align="middle">
          <Col span={24} className={styles.imageWrapper}>
            <img src={thankYouImage} alt="thankyou" />
          </Col>
          <Col className={styles['header-title']} span={24}>
            Cảm ơn bạn đã đăng ký nhận các dịch vụ miễn phí của VnRebates: <b>{text}</b>
          </Col>
          <Col className={styles['header-requirement-text']} span={24}>
            Support của VnRebates sẽ liên hệ lại với bạn qua Zalo hoặc điện thoại để hỗ trợ bạn sớm nhất.
            Chúc bạn có thật nhiều trải nghiệm bổ ích cùng VnRebates!
          </Col>
          <Col className={styles.supportText} span={24}>
            Để không phải chờ đợi lâu, bạn có thể liên hệ với support qua
            <div><VnrLink to="https://t.me/vnrebatessupport">TELEGRAM</VnrLink> HOẶC <VnrLink to="https://m.me/vnrebates">MESSENGER</VnrLink></div>
          </Col>
        </Row>
      </div>
    </ModalHook>
  )
}

export default PopupUserSentInformation;