import { request } from "@utils/requestWp";

import dataCategories from "./categories";

export const getAcf = ({ size = 100, filedGroup }) => {
  return request({
    url: "/wp-json/acf/v3/" + filedGroup,
    method: "GET",
    params: {
      // page: page,
      per_page: size,
    },
  });
};

export const getPosts = ({ categories = [], page = 1, size = 10 } = {}) => {
  const categoryIds = dataCategories
    .filter((cate) => categories.includes(cate.slug))
    .map((cate) => cate.id);
  return request({
    url: "/wp-json/wp/v2/posts",
    method: "GET",
    params: {
      page: page,
      custom_per_page: size,
      categories: categoryIds.join(","),
    },
  });
};

export const getPostById = (id = "") => {
  return request({
    url: `/wp-json/wp/v2/posts/${id}`,
    method: "GET",
  });
};

export const getPostMedia = (id = "") => {
  return request({
    url: `/wp-json/wp/v2/media/${id}`,
    method: "GET",
  });
};

export const getTopicGroup = () => {
  return getAcf({ filedGroup: "topic-group" });
};

export const getBroker = () => {
  return getAcf({ filedGroup: "forex-broker" });
};
export const getSpecialTopic = () => {
  return getAcf({ filedGroup: "topic-group" });
};
export const getLandingPage = () => {
  return getAcf({ filedGroup: "landing-pages" });
};
export const getConfigStatic = () => {
  return getAcf({ filedGroup: "config-static" });
};
export const getAcfOptions = () => {
  return getAcf({ filedGroup: "options/options/" });
};

export const getUserById = (per_page = 100, id = "") => {
  return request({
    url: `/wp-json/wp/v2/users/${id}`,
    method: "GET",
    params: {
      per_page: per_page,
    },
  });
};

export const getMostViewPost = () => {
  return getAcf({ filedGroup: "group-posts/26872" });
};

export const getBrokerBySlug = ({ slug }) => {
  return request({
    url: "/wp-json/wp/v2/forex-broker",
    method: "GET",
    params: {
      slug,
    },
  });
};

export const getTopicGroupById = (id = "") => {
  return request({
    url: `/wp-json/wp/v2/topic-group/${id}`,
    method: "GET",
  });
};
export const getPostMediaTopic = (includeIds = []) => {
  return request({
    url: `/wp-json/wp/v2/posts`,
    method: "GET",
    params: {
      include: includeIds.join(","),
    },
  });
};

export const getPostsAll = ({ page = 1 } = {}) => {
  return request({
    url: "/wp-json/wp/v2/posts",
    method: "GET",
    params: {
      page: page,
      custom_per_page: 300,
    },
  });
};

// https://vnrebates.net/wp-json/wp/v2/topic-group
export const getTopicGroupV2ById = async (page = 1, per_page = 100) => {
  return request({
    url: `/wp-json/wp/v2/topic-group?page=${page}&per_page=${per_page}`,
    method: "GET",
  });
};

// https://vnrebates.net/wp-json/wp/v2/group-posts
export const getGroupPostV2 = async (page = 1, per_page = 100) => {
  return request({
    url: `/wp-json/wp/v2/group-posts?page=${page}&per_page=${per_page}`,
    method: "GET",
  });
};

export const getAllBroker = (page = 1, per_page = 100) => {
  return request({
    url: "/wp-json/wp/v2/forex-broker",
    method: "GET",
    params: {
      page: page,
      per_page: per_page,
    },
  });
};
