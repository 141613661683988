import { REBATE_TYPE } from '@components/common/constants';
import { getAllBroker } from '@services/wp.service';
import produce from 'immer';
import { DOMAIN } from "src/constants";

import { getBrokers } from '../../services/broker.service';
import baseEntity, { sealObject } from './baseEntity.model';

const ENTITY_DEFAULT = {
  id: undefined,
  active: undefined,
  code: undefined,
  image: undefined,
  name: undefined,
  paymentType: undefined,
  rateRebateCurrency: undefined,
  rateRebateCurrencyType: undefined,
  rateRebateGold: undefined,
  rateRebateGoldType: undefined,
  rateRebateUserCurrencyText: undefined,
  rateRebateUserCurrency: undefined,
  rateRebateUserCurrencyType: undefined,
  rateRebateUserGoldText: undefined,
  rateRebateUserGold: undefined,
  rateRebateUserGoldType: undefined,
  templateReasonInvalidAccount: undefined,
  tutorialTransferIBType: undefined,
  tutorialTransferIBReason: undefined,
  tutorialTransferIBBtnOpenAccountText: undefined,
  tutorialTransferIBBtnOpenAccountUrl: undefined,
  tutorialTransferIBOpenAccountContent: undefined,
  tutorialTransferIBEmailSendTo: undefined,
  tutorialTransferIBEmailSubject: undefined,
  tutorialTransferIBEmailContent: undefined,
  tutorialTransferIBHiddenStepSubmitAccount: undefined,
};

export const entityBroker = {
  name: 'entityBroker',
  state: { ids: {} },
  reducers: {
    ...baseEntity(ENTITY_DEFAULT),
    saveReducers: produce((state, entities = []) => {
      entities.forEach(entity => {
        state.ids[entity.id] = sealObject(ENTITY_DEFAULT, state.ids[entity.id], entity);
      });
    }),
  },
  effects: dispatch => ({
    saves: () => {
    },
    getAllBrokerService: async (payload, rootState) => {
      const existData = Object.values(rootState.entityBroker.ids);
      if (existData.length > 0) {
        return existData;
      }
      
      const { data: entities } = await getBrokers();
      const { data: entityBrokerInWP } = await getAllBroker();
      const entityBrokerInWPByCode = entityBrokerInWP.map(item => {
        const {
          code,
          rebate_money,
          rebate_gold,
          payment_type,
          template_reason_invalid_account = "",
          tutorial_transfer_ib_type,
          tutorial_transfer_ib_reason,
          tutorial_transfer_ib_btn_open_account_text,
          tutorial_transfer_ib_btn_open_account_url,
          tutorial_transfer_ib_open_account_content,
          tutorial_transfer_ib_email_send_to,
          tutorial_transfer_ib_email_subject,
          tutorial_transfer_ib_email_content,
          tutorial_transfer_ib_hidden_step_submit_account,
        } = item.acf;
        // currency
        const rateRebateUserCurrencyType =
          !rebate_money || rebate_money?.includes("%")
            ? REBATE_TYPE.PERCENT.code
            : REBATE_TYPE.MONEY.code;
        const rateRebateUserCurrency = rebate_money ? parseInt(
          rebate_money?.replace("USD/lot", "")
        ) : 0;
        // gold
        const rateRebateUserGoldType =
          rebate_gold === "" || rebate_gold?.includes("%")
            ? REBATE_TYPE.PERCENT.code
            : REBATE_TYPE.MONEY.code;
        const rateRebateUserGold = rebate_gold ? parseInt(rebate_gold?.replace("USD/lot", "")) : 0;
        const broker = {
          id: item.id,
          name: item.name,
          image: item.metadata.avatar.url?.replace('https://vnrebates.net', DOMAIN),
          //image: node?.acf?.avatar?.localFile?.publicURL,
          code,
          paymentType: payment_type,
          rateRebateUserCurrencyText: rebate_money,
          rateRebateUserCurrency,
          rateRebateUserCurrencyType,
          rateRebateUserGoldText: rebate_gold,
          rateRebateUserGold,
          rateRebateUserGoldType,
          templateReasonInvalidAccount: template_reason_invalid_account,
          tutorialTransferIBType: tutorial_transfer_ib_type,
          tutorialTransferIBReason: tutorial_transfer_ib_reason,
          tutorialTransferIBBtnOpenAccountText:
            tutorial_transfer_ib_btn_open_account_text,
          tutorialTransferIBBtnOpenAccountUrl:
            tutorial_transfer_ib_btn_open_account_url,
          tutorialTransferIBOpenAccountContent:
            tutorial_transfer_ib_open_account_content,
          tutorialTransferIBEmailSendTo: tutorial_transfer_ib_email_send_to,
          tutorialTransferIBEmailSubject: tutorial_transfer_ib_email_subject,
          tutorialTransferIBEmailContent: tutorial_transfer_ib_email_content,
          tutorialTransferIBHiddenStepSubmitAccount:
            !!tutorial_transfer_ib_hidden_step_submit_account,
        };
        return broker;
      }).reduce((obj, item) => {
        obj[item.code] = { ...item };
        return obj;
      }, {});
      const entitiesWithDataWP = entities.map(entity => {
        const {
          image,
          paymentType,
          rateRebateUserCurrencyText,
          rateRebateUserCurrency,
          rateRebateUserCurrencyType,
          rateRebateUserGoldText,
          rateRebateUserGold,
          rateRebateUserGoldType,
          templateReasonInvalidAccount,
          tutorialTransferIBType,
          tutorialTransferIBReason,
          tutorialTransferIBBtnOpenAccountText,
          tutorialTransferIBBtnOpenAccountUrl,
          tutorialTransferIBOpenAccountContent,
          tutorialTransferIBEmailSendTo,
          tutorialTransferIBEmailSubject,
          tutorialTransferIBEmailContent,
          tutorialTransferIBHiddenStepSubmitAccount,
        } = entityBrokerInWPByCode[entity.code] || {};
        const activeInWP = !!entityBrokerInWPByCode[entity.code];
        const activeInDB = entity.active;
        const active = activeInWP === false ? false : activeInDB;
        const brokerWithDataWP = {
          ...entity,
          active,
          image,
          paymentType,
          rateRebateUserCurrencyText,
          rateRebateUserCurrency,
          rateRebateUserCurrencyType,
          rateRebateUserGoldText,
          rateRebateUserGold,
          rateRebateUserGoldType,
          templateReasonInvalidAccount,
          tutorialTransferIBType,
          tutorialTransferIBReason,
          tutorialTransferIBBtnOpenAccountText,
          tutorialTransferIBBtnOpenAccountUrl,
          tutorialTransferIBOpenAccountContent,
          tutorialTransferIBEmailSendTo,
          tutorialTransferIBEmailSubject,
          tutorialTransferIBEmailContent,
          tutorialTransferIBHiddenStepSubmitAccount: !!tutorialTransferIBHiddenStepSubmitAccount,
        };
        return brokerWithDataWP;
      });
      dispatch.entityBroker.saveReducers(entitiesWithDataWP);
      return entitiesWithDataWP;
    },
  }),
};
